@import url('https://fonts.googleapis.com/css2?family=Dosis&display=swap');

/*Garde ça en haut...*/
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  font-family: 'Dosis', sans-serif !important;


}

h2 {
  font-size: 20px;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 5px;
}



h3,
h4 {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: unset;
  margin-top: unset;
}

h4 {
    margin-top: 10px;
}

.my-svg path {
  fill: #000;
  /* Nouvelle couleur */
}

header {}

.logo {
  width: 112px;
}

.menu ul {
  list-style: none;
  display: flex;
}

.menu ul li {
  margin-left: 20px;
}

.menu ul li a {
  text-decoration: none;
  color: black;
}

.menu-mobile {
  flex-direction: column;
  width: 100%;
}

.menu-mobile ul {
  list-style: none;
  flex-direction: column;
  display: flex;
  margin-left: 10%;
}

.menu-mobile ul li {

  margin-left: 20px;
  line-height: 3em;

}

.menu-mobile ul li a {
  text-decoration: none;
  color: white;
}

.burger {
  display: none;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
}

.overlay-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.overlay ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.overlay ul li {
  margin-bottom: 20px;
}

.overlay ul li a {
  color: white;
  text-decoration: none;
}

.menu {
  display: none;
}

.burger {
  display: block;
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 0 0 32px 32px;
  padding-top: 2em;
  padding-left: 10%;
  background-color: #252525;
  fill: white;
  width: 100%;
  clip-path: circle(10% at calc(10% + 13px) calc(2em + 15px));
  transition: all 0.2s ease-in-out;
}

.burger.toggled {
  clip-path: circle(1000% at calc(10% + 13px) calc(2em + 15px));
}

.animsition {
  height: 100%;
}

nav {
  display: flex;
  align-items: center;
}

.dwrap {
  background: white;
}

.full-page {
  /* height: 100%; */
  width: 100%;
  /* background: linear-gradient(180deg, #000 0%, #9BC2CE 99.99%, rgba(76, 165, 251, 0.00) 100%); */
  background: linear-gradient(0deg, rgb(0 0 0 / 80%) 0%, rgb(7 7 7 / 80%) 100%), url(assets/images/bannierre.jpg), lightgray 50% / cover no-repeat;
  background-size: cover;
  background-repeat: no-repeat;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
}

.top-page {
  padding-top: 83px;
  color: white;
  flex-grow: 1;
  padding-bottom: 10px;
}

.photo img {
  width: 140px;
  height: 140px;
  border-radius: 100%;
  border: 7px solid #FFF;
  /* background: url(assets/images/medusa.jpg) lightgray 50% / cover no-repeat; */
  background-color: #E6BF9F;
  display: block;

}

/* .contact {
    display: inline-flex;
    align-items: flex-start;
    gap: 60px;
}

.contact-icon {
    width: 80px;
} */

.cwrap {
  border-radius: 50px 50px 0px 0px;
  background-color: white;
  padding-top: 30px;
}

.contact {

  border-radius: 17px;
  /* background: linear-gradient(180deg, #000 0%, #9BC2CE 99.99%, rgba(76, 165, 251, 0.00) 100%); */
  background: linear-gradient(0deg, rgb(0 0 0 / 80%) 0%, rgb(7 7 7 / 80%) 100%), url(assets/images/bannierre.jpg), lightgray 50% / cover no-repeat;
  background-repeat: no-repeat;
  display: flex;
  gap: 25px;
  justify-content: center;
  padding: 20px;
  margin-inline: 45px;
}

.contact-icon {
  width: 31px;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}


.contact-icon a {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bottom-page {
  /* z-index: 99; */
  position: relative;
  padding: 33px;
  padding-right: 30px;
  padding-bottom: 0;
  padding-top: 0;
  /* border-radius: 20px 20px 0px 0px; */
  /* border-radius: 50px 50px 0px 0px; */
  background: #ffffff;
  /* max-height: 40vh; */
  /* overflow: scroll; */
  width: 100%;
  display: flex;
  flex-direction: column;
}

#social {
  margin-top: 2em;
}

#links {
  margin-top: 2em;
}
#contact {
  flex-shrink: 0;

  ;
}


#newcontact {
  display: flex;
  flex-direction: column;
  justify-content: center;
}



#document {
  margin-top: 2em;
  flex-shrink: 0;

  ;
}


.section-title {
  color: #9BC2CE;
  font-family: 'Dosis', sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

}

.section-content-scroll {
  display: flex;
  justify-content: space-between;
  /*a voir si pertinent...*/
  gap: 30px;
  overflow-x: scroll;
  width: 100%;
  padding: 20px;
}

.section-content-scroll::-webkit-scrollbar {
  display: none;
}

.section-content-scroll {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.section-content::-webkit-scrollbar {
  display: none;
}

.section-content {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}


.section-content {
  display: grid;
  grid-template-columns: repeat(auto-fill, 89px);
  width: 100%;
  padding-block: 20px;
  row-gap: 15px;
  column-gap: 15px;
  justify-content: space-between
}


.card {
  display: flex;
  width: 100%;
  aspect-ratio: 1;
  border-radius: 10px;
  /* background: #d9e4ed; */
}

.card-header {
  position: relative;
}


.card-content img {
  width: 60px;
  aspect-ratio: 1;
  object-fit: contain;
}



.card-document {
  /* width: 89px; */
  height: 98px;
  border-radius: 20px;
  background: #f5f5f5;
  flex-basis: 33%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.card-document-header {
  min-height: 20%;
  position: relative;
}

.card-document::before {
  content: "";
  position: absolute;
  top: 8px;
  right: 8px;
  width: 9px;
  height: 12px;
  background-image: url(assets/images/download.svg);
  background-size: cover;
}

.card-document-content {
  display: flex;
}



.card-contact {
  max-width: 200px;
  max-height: 200px;
  width: 100%;
  aspect-ratio: 1;
  border-radius: 20px;
  background: #f5f5f5;
  flex-basis: 33%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.card-contact-header {
  min-height: 20%;
  position: relative;
}

.card-contact::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  /* Ajustez la taille selon vos besoins */
  /* height: 40px; */
  /* Ajustez la taille selon vos besoins */
  background-image: url(assets/images/eye.svg);
  /* Chemin vers votre fichier SVG de téléchargement */
  background-size: cover;
  margin-right: 10px;
  margin-top: 10px;
  aspect-ratio: 14/11;
}

.card-contact-content {
  min-height: 80%;
}



html,
body {
  height: 100%;
}

footer {
  background-color: white;
  padding: 10px;
}

footer p {
  color: #9BC2CE;
  text-align: center;
}

.doc-text {
  color: #000;
  text-align: center;
  line-height: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 9px;
  text-wrap: nowrap;
  padding-inline: 10px;
}

.profil {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

header {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: sticky;
  position: sticky;
  top: 0;
  /* padding: 20px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  background-color: white;
  padding: 10px;
  /* min-height: 10%; */
}

.buttons-container {
  display: flex;
  gap: 10px;
  justify-content: center;
  justify-content: space-around;
  align-items: flex-start;
  margin-top: 1em;
}

.buttons-container button {
  display: flex;
  padding: 10px 25px 10px 25px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 28px;
  background: linear-gradient(90deg, #000 0%, #000 100%);
  color: #FFF;
  text-align: center;
  font-family: 'Dosis', sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-style: none;
  width: 50%;
}

.jolie-btn {
  display: flex;
  padding: 10px 25px 10px 25px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 28px;
  background: linear-gradient(90deg, #000 0%, #000 100%);
  color: #FFF;
  text-align: center;
  font-family: 'Dosis', sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-style: none;
  width: 100%;
}

.card-document-footer {
  width: 100%;
}

/* Styles pour la div qui prend toute la page */
.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999999;
}

/* CSS */
.spinner {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 10px solid #ccc;
  border-top: 10px solid #333;
  animation: spin 1s linear infinite;
  /* Animation de rotation */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


.aninimated-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  text-transform: uppercase;
  text-align: center;
}
